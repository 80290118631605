var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Animate from 'rc-animate';
import classNames from 'classnames';
import ScrollNumber from './ScrollNumber';
import Ribbon from './Ribbon';
import { ConfigContext } from '../config-provider';
import { cloneElement } from '../_util/reactNode';
import { isPresetColor } from './utils';
export { ScrollNumberProps } from './ScrollNumber';
const Badge = (_a) => {
    var { prefixCls: customizePrefixCls, scrollNumberPrefixCls: customizeScrollNumberPrefixCls, children, status, text, color, count = null, overflowCount = 99, dot = false, title, offset, style, className, showZero = false } = _a, restProps = __rest(_a, ["prefixCls", "scrollNumberPrefixCls", "children", "status", "text", "color", "count", "overflowCount", "dot", "title", "offset", "style", "className", "showZero"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('badge', customizePrefixCls);
    const getNumberedDisplayCount = () => {
        const displayCount = count > overflowCount ? `${overflowCount}+` : count;
        return displayCount;
    };
    const hasStatus = () => {
        return !!status || !!color;
    };
    const isZero = () => {
        const numberedDisplayCount = getNumberedDisplayCount();
        return numberedDisplayCount === '0' || numberedDisplayCount === 0;
    };
    const isDot = () => {
        return (dot && !isZero()) || hasStatus();
    };
    const getDisplayCount = () => {
        // dot mode don't need count
        if (isDot()) {
            return '';
        }
        return getNumberedDisplayCount();
    };
    const getScrollNumberTitle = () => {
        if (title) {
            return title;
        }
        return typeof count === 'string' || typeof count === 'number' ? count : undefined;
    };
    const getStyleWithOffset = () => {
        if (direction === 'rtl') {
            return offset
                ? Object.assign({ left: parseInt(offset[0], 10), marginTop: offset[1] }, style) : style;
        }
        return offset
            ? Object.assign({ right: -parseInt(offset[0], 10), marginTop: offset[1] }, style) : style;
    };
    const isHidden = () => {
        const displayCount = getDisplayCount();
        const isEmpty = displayCount === null || displayCount === undefined || displayCount === '';
        return (isEmpty || (isZero() && !showZero)) && !isDot();
    };
    const renderStatusText = () => {
        const hidden = isHidden();
        return hidden || !text ? null : <span className={`${prefixCls}-status-text`}>{text}</span>;
    };
    const renderDisplayComponent = () => {
        const customNode = count;
        if (!customNode || typeof customNode !== 'object') {
            return undefined;
        }
        return cloneElement(customNode, {
            style: Object.assign(Object.assign({}, getStyleWithOffset()), (customNode.props && customNode.props.style)),
        });
    };
    const renderBadgeNumber = () => {
        const scrollNumberPrefixCls = getPrefixCls('scroll-number', customizeScrollNumberPrefixCls);
        const displayCount = getDisplayCount();
        const bDot = isDot();
        const hidden = isHidden();
        const scrollNumberCls = classNames({
            [`${prefixCls}-dot`]: bDot,
            [`${prefixCls}-count`]: !bDot,
            [`${prefixCls}-multiple-words`]: !bDot && count && count.toString && count.toString().length > 1,
            [`${prefixCls}-status-${status}`]: !!status,
            [`${prefixCls}-status-${color}`]: isPresetColor(color),
        });
        let statusStyle = getStyleWithOffset();
        if (color && !isPresetColor(color)) {
            statusStyle = statusStyle || {};
            statusStyle.background = color;
        }
        return hidden ? null : (<ScrollNumber prefixCls={scrollNumberPrefixCls} data-show={!hidden} className={scrollNumberCls} count={displayCount} displayComponent={renderDisplayComponent()} // <Badge status="success" count={<Icon type="xxx" />}></Badge>
         title={getScrollNumberTitle()} style={statusStyle} key="scrollNumber"/>);
    };
    const statusCls = classNames({
        [`${prefixCls}-status-dot`]: hasStatus(),
        [`${prefixCls}-status-${status}`]: !!status,
        [`${prefixCls}-status-${color}`]: isPresetColor(color),
    });
    const statusStyle = {};
    if (color && !isPresetColor(color)) {
        statusStyle.background = color;
    }
    const badgeClassName = classNames(className, prefixCls, {
        [`${prefixCls}-status`]: hasStatus(),
        [`${prefixCls}-not-a-wrapper`]: !children,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    });
    // <Badge status="success" />
    if (!children && hasStatus()) {
        const styleWithOffset = getStyleWithOffset();
        const statusTextColor = styleWithOffset && styleWithOffset.color;
        return (<span {...restProps} className={badgeClassName} style={styleWithOffset}>
        <span className={statusCls} style={statusStyle}/>
        <span style={{ color: statusTextColor }} className={`${prefixCls}-status-text`}>
          {text}
        </span>
      </span>);
    }
    return (<span {...restProps} className={badgeClassName}>
      {children}
      <Animate component="" showProp="data-show" transitionName={children ? `${prefixCls}-zoom` : ''} transitionAppear>
        {renderBadgeNumber()}
      </Animate>
      {renderStatusText()}
    </span>);
};
Badge.Ribbon = Ribbon;
export default Badge;
